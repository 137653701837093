<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right" >
      <el-breadcrumb-item>应用管理</el-breadcrumb-item>
      <el-breadcrumb-item>品类投放管理</el-breadcrumb-item>
    </el-breadcrumb>
    <br />
    <el-row>
      <!-- <el-button type="primary" @click="handleCreate">添加</el-button>
      <el-button type="primary" @click="handleFoms">手动录入</el-button> -->
        <el-col :span="3">
            <el-select v-model="listQuery.name" value-key="id" clearable   placeholder="app" style="margin-right: 30px" @change="qappIdChange" >
                <el-option
                    v-for="item in appOptions"
                    :key="item.id"
                    :label="item.name"
                    :value="item">
                </el-option>
            </el-select>
        </el-col>

        <!-- <el-col :span="3">
            <el-select v-model="listQuery.id" placeholder="广告品类" clearable style="margin-right: 30px">
                <el-option
                    v-for="item in categoryList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                </el-option>
            </el-select>
        </el-col> -->

        <!-- <el-col :span="3" style="margin-right: 30px">
            <el-input v-model="listQuery.power" placeholder="权重配置"></el-input>
        </el-col> -->

        <!-- <el-col :span="3" style="margin-right: 30px">
            <el-select v-model="listQuery.tagId" placeholder="投放标签" clearable>
                <el-option
                    v-for="item in tagListAll"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                </el-option>
            </el-select>
        </el-col> -->

        <el-col :span="3" style="margin-right: 30px">
            <el-button type="primary" @click="handleCreate">新增</el-button>
        </el-col>
    </el-row>


    <!--表单 BEGIN -->
    <el-dialog :title="textMap[dialogStatus]" :visible.sync="dialogFormVisible">
      <el-form ref="dataForm" :rules="rules" :model="temp" label-position="left" label-width="100px" style="width: 600px; margin-left:50px;">

        <el-form-item label="应用" prop="appId">
          <el-select v-model="temp.appId" :disabled="dialogStatus === 'update'"  style="width:500px"   placeholder="应用">
            <el-option
                v-for="item in appOptions"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="广告品类" prop="categoryId">
          <el-select v-model="temp.categoryId" value-key="id" :disabled="dialogStatus === 'update'"  style="width:500px" placeholder="广告品类">
            <el-option
                v-for="item in categoryList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="投放权重" prop="weight">
          <el-input v-model="temp.weight"  placeholder="请输入"/>
        </el-form-item>
        
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">
          取消
        </el-button>
        <el-button type="primary" @click="dialogStatus==='create'?createData():updateData()">
          保存
        </el-button>
      </div>
    </el-dialog>

    <!--表单 END -->
    <br />
    <div style="background: #fff ">
    <el-table
        ref="singleTable"
        :data="tableData"
        border
        fit
        style="width: 100%;height: 100%"
    >
      <el-table-column
          fixed
          fit
          align="center"
          type="index"
          min-width="20">
      </el-table-column>

      <el-table-column prop="appName" label="app名称" min-width="40" />
      <el-table-column prop="categoryName" label="广告品类" min-width="60" />
      <el-table-column prop="weight" label="品类权重" min-width="40" />
      <el-table-column prop="createTime" label="创建时间" min-width="40" />

      <el-table-column label="操作" align="center" min-width="60" >
        <template slot-scope="scope">
          <el-button
              size="mini"
              type="primary"
              @click="handleUpdate(scope.$index, scope.row)">编辑</el-button>
          <el-button
              size="mini"
              type="danger"
              @click="handleDelete(scope.$index, scope.row)">删除</el-button>
        </template>
      </el-table-column>
      <!-- <el-table-column label="是否发布" align="center" min-width="50">
        <template slot-scope="scope">
          <el-switch
            style="display:block;"
            v-model="scope.row.cp"
            @change="change(scope.row,scope.$index)"
            active-text="是"
            :disabled="scope.row.cp"
            inactive-text="否">
          </el-switch>
        </template>
      </el-table-column> -->
    </el-table>

    <el-pagination style="background: #fff ;margin-top: 20px;margin-left: 10px"
                   background @current-change="handleCurrentChange" :page-size="listQuery.size" layout="total,prev, pager, next" :total="total">
    </el-pagination>
    <div style="height: 30px;" />

    </div>


  </div>
</template>

<script>

import { listAllApi } from "@/api/apps";
import { categoryListAll } from '@/api/ads';
import { categoryAdd, categoryList, categoryEdit, categoryDel } from "@/api/adver";

export default {
  name: "appProfit",
  inject: ['load','closeLoad'],
  components: {  },
  data() {
    const typeNameRules = (rule,value,callback) =>{
        const reg = /\d/g
        if(value !== '') {
            if (!reg.test(value)) {
                callback(new Error('请输入正确的权重'))
            } else {
                callback()
            }
        } else {
            callback(new Error('不能为空'))
        }
    }
    return {
      total: 0,
      listQuery: {
        current: 1,
        size: 10,
        dateYMd:'',
        appId:'',
        name:'',
        categoryId:'',
      },
      switchValue1: false,
      dialogFormVisible: false,
      dialogFormVisible1:false,
      dialogStatus: '',
      dialogStatus1:'',
      textMap: {
        update: '编辑',
        create: '添加'
      },
      rules: {
        appId: [{ required: true, message: '不能为空', trigger: 'blur' }],
        categoryId: [{ required: true, message: '不能为空', trigger: 'blur' }],
        weight: [{ required: true, validator: typeNameRules, trigger: 'change' }],
      },
      categoryList:[],
      temp: {
        appId:'',
        categoryId:'',
        weight:0,
      },
      appName:'',
      tableData: [],
      appOptions:[],
    }
  },
  created() {
    // this.getList();
    this.getInitOptions();
    this.getCategoryListAll()
    this.getCategoryList()
  },
  methods:{
    getCategoryList(data){
      categoryList({current: 1,size: 1000,appId: data}).then(response => {
          if(response.code === 1) {
            this.closeLoad()
              this.tableData = response.body.records
              this.total = response.body.total
          }
      })
    },
    getInitOptions(){
      listAllApi({current: 1,size: 1000,}).then(response => {
          if(response.code === 1) {
              this.appOptions = response.body.records
          }
      })
    },
    getCategoryListAll() {
      categoryListAll({}).then(response => {
          if(response.code === 1) {
              this.categoryList = response.body
          }
      })
    },
    qappIdChange(val){
      this.load()
      this.listQuery.current = 1;
      this.getCategoryList(val.id)
    },
    // getTagListAll() {
    //   tagListAll({}).then(response => {
    //     // console.log(response)
    //     if(response.code === 1) {
    //         this.tagListAll = response.body
    //     }
    //   })
    // },

    // qadIdChange(val){
    //   console.info(val)
    //   this.listQuery.current = 1;
    //   this.getList();

    // },
   
    handleCurrentChange(val) {
      this.listQuery.current = val;
    //   this.getList()
    },
    handleCreate() {
      this.resetTemp()
      this.dialogStatus = 'create'
      this.dialogFormVisible = true
      this.$nextTick(() => {
        this.$refs['dataForm'].clearValidate()
      })
    },
    handleFoms() {
      this.dialogFormVisible1 = true
      this.resetFomes()
      this.dialogStatus1 = 'create'
      this.$nextTick(() => {
        this.$refs['dataForms'].clearValidate()
      })
    },
    datechange(){
      console.log(this.listQuery.dateYMd);
      // this.dateYMd = this.listQuery.dateYMd
      this.listQuery.current = 1;
    //   this.getList();

    },
    createData() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          categoryAdd(this.temp).then(() => {
            //this.tableData.unshift(this.temp)
            // this.getList();
            this.dialogFormVisible = false
            this.$notify({
              title: 'Success',
              message: '新增成功',
              type: 'success',
              duration: 2000
            })
            this.getCategoryList()
          })
        }
      })
    },
    updateData() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          const tempData = Object.assign({}, this.temp)
          categoryEdit(tempData).then(() => {
            this.dialogFormVisible = false
            this.$notify({
              title: 'Success',
              message: '修改成功',
              type: 'success',
              duration: 2000
            })
            this.getCategoryList()
          })
        }
      })
    },
    handleDelete(index, row) {
      console.log(index, row);
      this.$confirm('此操作将删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        categoryDel(row).then(() => {
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
          this.getCategoryList()
        })

      }).catch(() => {});
    },
    // resetFomes() {
    //   this.fomrs = {
    //     appId: '',
    //     dateYMd: '',
    //     fomrs:''
    //   }
    // },
    resetTemp() {
      this.temp = {
        appId:'',
        categoryId:'',
        weight:0,
      }
    },

    handleUpdate(index, row) {
      console.log(row)
      this.temp = Object.assign({}, row)
      this.dialogStatus = 'update'
      this.dialogFormVisible = true
      this.$nextTick(() => {
        this.$refs['dataForm'].clearValidate()
      })
    }
  }
}

</script>

<style scoped>


</style>
